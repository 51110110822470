<template>
  <VDialog
    v-model="visibility"
    max-width="600px"
  >
    <VCard>
      <VCardTitle>
        <span class="headline">{{ language.CREATE_SUPPLY }}</span>
      </VCardTitle>
      <VDivider class="mb-3"/>
      <VCardText>
        <VContainer class="py-0">
          <div class="text-center" v-if="loading">
            <v-progress-circular
              :size="50"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </div>
          <div v-else>
            <VRow>
              <VCol cols="12">
                <VTextField
                  outlined dense
                  :label="language.NAME"
                  required
                  v-model="item.name"
                  :error-messages="nameErrors"
                ></VTextField>
              </VCol>
              <VCol cols="12" md="6">
                <VSelect
                  label="Адрес відправника"
                  :items="Object.values(addresses)"
                  :item-value="'uuid'"
                  :item-text="'name'"
                  :no-data-text="language.NO_DATA_IS_AVAILABLE"
                  outlined
                  dense
                  v-model="item.uuid_sender_address"
                  :error-messages="uuidSenderAddressErrors"
                >
                </VSelect>
              </VCol>
              <VCol cols="12" md="6">
                <VSelect
                  label="Адрес отримувача"
                  :items="Object.values(addresses)"
                  :item-value="'uuid'"
                  :item-text="'name'"
                  :no-data-text="language.NO_DATA_IS_AVAILABLE"
                  outlined
                  dense
                  v-model="item.uuid_delivery_address"
                  :error-messages="uuidDeliveryAddressErrors"
                >
                </VSelect>
              </VCol>
              <VCol cols="12" class="my-0 py-0">
                <VTextarea
                  no-resize
                  rows="3"
                  outlined dense
                  :label="language.DESCRIPTION"
                  v-model="item.description"
                  :error-messages="descriptionErrors"
                  required
                ></VTextarea>
              </VCol>
            </VRow>
          </div>
          <div>
            <h3>Supply items</h3>
            <VDivider class="mb-2" />
            <div
              class="row align-center"
              v-for="(supplyItem, index) in supplyItems"
              :key="index"
            >
              <VCol cols="12" md="8">
                <h3>{{ getProductNameByUuid(supplyItem.uuid) }}</h3>
              </VCol>
              <VCol cols="12" md="3">
                <VTextField
                  type="number"
                  outlined
                  dense
                  label="Кількість"
                  hide-details
                  :value="supplyItem.amount"
                  min="1"
                />
              </VCol>
              <VCol cols="12" md="1">
                <VIcon color="red" @click="onDeleteSupplyItem(index)">mdi-delete</VIcon>
              </VCol>
            </div>
            <div class="row align-center" v-if="adding">
              <VCol cols="12" md="7">
                <VSelect
                  outlined
                  dense
                  :hide-details="!newItemUuidErrors.length"
                  :error-messages="newItemUuidErrors"
                  item-text="item_name"
                  :items="availableItems"
                  :no-data-text="language.NO_DATA_IS_AVAILABLE"
                  item-value="uuid"
                  v-model="newItem.uuid"
                />
              </VCol>
              <VCol col="12" md="3">
                <VTextField
                  outlined
                  dense
                  type="number"
                  min="1"
                  v-model="newItem.amount"
                  :hide-details="!newItemAmountErrors.length"
                  label="Кількість"
                  :error-messages="newItemAmountErrors"
                />
              </VCol>
              <VCol col="12" md="1">
                <VIcon color="red" @click="onCancelAdd">mdi-close</VIcon>
              </VCol>
              <VCol col="12" md="1">
                <VIcon color="green" @click="onAddItem">mdi-check</VIcon>
              </VCol>
            </div>
            <div class="text-center" v-else>
              <VIcon size="40" @click="adding = true">mdi-plus-circle-outline</VIcon>
            </div>
          </div>
        </VContainer>
      </VCardText>
      <VCardActions>
        <VRow class="my-0 py-0">
          <VCol cols="12" md="6" sm="12" class="my-0 py-0">
            <VBtn
              @click="visibility = false"
              block
              class="w-100 br-10"
              dark
              :disabled="loading"
            >
              {{ language.CANCEL }}
            </VBtn>
          </VCol>
          <VCol cols="12" md="6" sm="12" class="my-0 py-0">
            <VBtn
              class="success-bg text-white text-transform-none br-10 w-100"
              block
              @click="onCreate"
              :loading="loading"
            >
              {{ language.CREATE }}
            </VBtn>
          </VCol>
        </VRow>
      </VCardActions>
    </VCard>
  </VDialog>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required, minValue } from 'vuelidate/lib/validators';
import language from '../../mixins/language';
import notifications from '../../mixins/notifications';
import supplyService from '../../services/request/supply/supplyService';
import EventBus from '../../events/EventBus';

export default {
  name: 'AddSupplyDialog',
  validations: {
    item: {
      name: { required },
      description: { required },
      uuid_sender_address: { required },
      uuid_delivery_address: { required },
    },
    newItem: {
      uuid: { required },
      amount: { required, minValue: minValue(1) },
    },
  },
  mixins: [language, notifications, validationMixin],
  data: () => ({
    loading: false,
    item: {
      name: '',
      description: '',
      uuid_sender_address: '',
      uuid_delivery_address: '',
    },
    newItem: {
      uuid: '',
      amount: 1,
    },
    supplyItems: [],
    adding: false,
  }),
  methods: {
    async onCreate() {
      this.$v.item.$touch();
      if (!this.$v.item.$invalid) {
        try {
          this.loading = true;
          const formData = new FormData();
          formData.append('name', this.item.name);
          formData.append('description', this.item.description);
          formData.append('uuid_sender_address', this.item.uuid_sender_address);
          formData.append('uuid_delivery_address', this.item.uuid_delivery_address);
          const createdDelivery = await supplyService.addSupplyItem(formData);
          // eslint-disable-next-line no-restricted-syntax
          for (const supplyItem of this.supplyItems) {
            const supplyData = new FormData();
            supplyData.append('uuid_item', supplyItem.uuid);
            supplyData.append('id_state', 1);
            supplyData.append('items_amount', supplyItem.amount);
            supplyData.append('uuid_delivery', createdDelivery.uuid);
            supplyService.addDeliveryItem(supplyData);
          }
          this.supplyItems = [];
          this.loading = false;
          EventBus.$emit('supply-added');
          this.setSuccessNotification('Постачання було успішно додано!');
          this.clearProperty('item');
          this.$v.item.$reset();
          this.visibility = false;
        } catch (e) {
          this.loading = false;
          this.setErrorNotification(e);
        }
      }
    },
    getProductNameByUuid(uuid) {
      return this.products.find((item) => item.uuid === uuid)?.item_name;
    },
    clearProperty(property) {
      Object.keys(this[property]).forEach((key) => {
        this[property][key] = '';
      });
      if (property === 'newItem') {
        this.newItem.amount = 1;
      }
    },
    onCancelAdd() {
      this.adding = false;
      this.clearProperty('newItem');
    },
    onAddItem() {
      this.$v.newItem.$touch();
      if (!this.$v.newItem.$invalid) {
        this.supplyItems.push({
          uuid: this.newItem.uuid,
          amount: this.newItem.amount,
        });
        this.clearProperty('newItem');
        this.adding = false;
        this.$v.$reset();
      }
    },
    onDeleteSupplyItem(index) {
      this.supplyItems = this.supplyItems.filter((value, elIndex) => elIndex !== index);
      this.clearProperty('newItem');
    },
  },
  props: {
    visible: {
      required: true,
      type: Boolean,
    },
    addresses: {
      required: true,
    },
    products: {
      required: true,
    },
  },
  computed: {
    availableItems: {
      get() {
        const unavailableUuids = this.supplyItems.map((item) => item.uuid);
        return this.products.filter((product) => !unavailableUuids.includes(product.uuid));
      },
      set() {},
    },
    visibility: {
      get() {
        return this.visible;
      },
      set() {
        this.$emit('close');
      },
    },
    newItemUuidErrors() {
      const errors = [];
      if (!this.$v.newItem.uuid.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.newItem.uuid.required
      && errors.push(this.language.isRequired('Item'));
      return errors;
    },
    newItemAmountErrors() {
      const errors = [];
      if (!this.$v.newItem.amount.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.newItem.amount.required
      && errors.push(this.language.isRequired('Кількість'));
      // eslint-disable-next-line no-unused-expressions
      !this.$v.newItem.amount.minValue
      && errors.push('Мінімальна кількість - 1!');
      return errors;
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.item.name.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.item.name.required
      && errors.push(this.language.isRequired(this.language.TITLE));
      return errors;
    },
    descriptionErrors() {
      const errors = [];
      if (!this.$v.item.description.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.item.description.required
      && errors.push(this.language.isRequired(this.language.DESCRIPTION));
      return errors;
    },
    uuidSenderAddressErrors() {
      const errors = [];
      if (!this.$v.item.uuid_sender_address.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.item.uuid_sender_address.required
      && errors.push(this.language.isRequired('Адреса відправника'));
      return errors;
    },
    uuidDeliveryAddressErrors() {
      const errors = [];
      if (!this.$v.item.uuid_delivery_address.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.item.uuid_delivery_address.required
      && errors.push(this.language.isRequired('Адреса отримувача'));
      // // eslint-disable-next-line no-unused-expressions
      // !this.$v.item.uuid_delivery_address.not
      // && errors.push('Адреса отримувача має відрізнятися!');
      return errors;
    },
  },
};
</script>

<style scoped>

</style>
