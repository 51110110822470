<template>
  <div ref="scrollWrappedElement">
    <VCard class="px-2 py-2 mb-2 position-relative">
      <VRow
        no-gutters
        v-if="$vuetify.breakpoint.smAndDown"
        :justify="permissions.can_search ? 'space-between' : 'end'"
        class="mb-3"
      >
        <VCol
          :cols="$vuetify.breakpoint.sm ? '5' : 'auto' || $vuetify.breakpoint.xs ? '6' : 'auto'"
          class="mr-4"
        >
          <v-autocomplete
            v-if="permissions.can_search"
            dense
            prepend-inner-icon="mdi-warehouse"
            :items="Object.values(depots)"
            :item-value="'uuid'"
            :item-text="'name'"
            hide-details
            v-model="chosenDeports"
            solo
            class="br-10"
            :menu-props="{ bottom: true, offsetY: true }"
            :no-data-text="$t('table.noData')"
            :label="$t('form.storage')"
            small-chips
            deletable-chips
          />
        </VCol>
        <VCol cols="auto" class="text-left">
          <VBtn
            depressed
            class="success-bg text-white text-transform-none br-10"
            @click="addSupplyInDialog = true"
            v-if="activeTab !== 1"
          >
            <VIcon left>mdi-plus</VIcon>
            <div v-if="!$vuetify.breakpoint.xs || !permissions.can_search">
              {{ $t("btn.create") }}
            </div>
            <package-down-icon fill="white" width="22" height="22" view-box-value="0 0 12 24" />
          </VBtn>
          <VBtn
            depressed
            class="success-bg text-white text-transform-none br-10"
            @click="itemDialog.visible = true"
            v-if="activeTab !== 0"
          >
            <VIcon left>mdi-plus</VIcon>
            <div v-if="!$vuetify.breakpoint.xs || !permissions.can_search">
              {{ $t("btn.create") }}
            </div>
            <package-up-icon fill="white" width="22" height="22" view-box-value="0 0 12 24" />
          </VBtn>
        </VCol>
      </VRow>
      <VRow
        no-gutters
        class="align-center justify-space-between pa-0 "
        style="border-bottom: 1px solid rgba(0, 0, 0, 0.12)"
        :style="$vuetify.breakpoint.mdAndDown ? 'border-top: 1px solid rgba(0, 0, 0, 0.12);' : ''"
      >
        <VRow
          no-gutters
          align="center"
          justify="center"
          justify-md="space-between"
          justify-lg="space-between"
          justify-sm="space-between"
        >
          <VCol class="pa-0" cols="auto">
            <VTabs
              show-arrows
              active-class="active-tab-class"
              background-color="transparent"
              slider-color="green"
              v-model="activeTab"
              @change="onChangeTab"
              class="supply-component"
            >
              <VTab> <package-down-icon class="mr-1" />{{ $t("supply.income_goods") }} </VTab>
              <VTab> <package-up-icon class="mr-1" />{{ $t("supply.shipped") }} </VTab>
            </VTabs>
          </VCol>
          <VRow justify="end" align="center" no-gutters v-if="!$vuetify.breakpoint.smAndDown">
            <VCol :cols="$vuetify.breakpoint.xs ? '5' : 'auto'" class="mr-4">
              <v-autocomplete
                v-if="permissions.can_search"
                dense
                prepend-inner-icon="mdi-warehouse"
                :items="Object.values(depots)"
                :item-value="'uuid'"
                :item-text="'name'"
                hide-details
                v-model="chosenDeports"
                solo
                class="br-10"
                :menu-props="{ bottom: true, offsetY: true }"
                :no-data-text="$t('table.noData')"
                :label="$t('form.storage')"
                small-chips
                deletable-chips
              />
            </VCol>
            <VCol cols="auto" class="text-left">
              <VBtn
                depressed
                class="success-bg text-white text-transform-none br-10"
                @click="addSupplyInDialog = true"
                v-if="activeTab !== 1"
              >
                <VIcon left>mdi-plus</VIcon>
                {{ $t("btn.create") }}
                <package-down-icon fill="white" width="22" height="22" view-box-value="0 0 12 24" />
              </VBtn>
              <VBtn
                depressed
                class="success-bg text-white text-transform-none br-10"
                @click="itemDialog.visible = true"
                v-if="activeTab !== 0"
              >
                <VIcon left>mdi-plus</VIcon>
                {{ $t("btn.create") }}
                <package-up-icon fill="white" width="22" height="22" view-box-value="0 0 12 24" />
              </VBtn>
            </VCol>
          </VRow>
        </VRow>
        <!--      <VCol class="text-right pa-0">-->
        <!--        <PeriodDropdown />-->
        <!--      </VCol>-->
      </VRow>
      <VTabsItems v-model="activeTab">
        <!--      <VTabItem class="pa-2">-->
        <!--        <SupplyRowList-->
        <!--          type="all"-->
        <!--          :filter="chosenDeports"-->
        <!--        />-->
        <!--      </VTabItem>-->
        <VTabItem>
          <SupplyRowList type="in" :filter="chosenDeports" @add-customer="addClientDialog = true" />
        </VTabItem>
        <VTabItem>
          <SupplyRowList type="out" :filter="chosenDeports" />
        </VTabItem>
      </VTabsItems>

      <AddSupplyDialog
        :visible="createDialog"
        :addresses="addresses"
        @close="createDialog = false"
        :products="products"
      />
      <AddSupplyInDialog
        v-if="addSupplyInDialog"
        :visible="addSupplyInDialog"
        @close="addSupplyInDialog = false"
        @add-customer="addClientDialog = true"
      />
      <!--    <AddSupplyOutDialog-->
      <!--      v-if="addSupplyOutDialog"-->
      <!--      :typeDialog="'add'"-->
      <!--      :supply="[]"-->
      <!--      :visible="addSupplyOutDialog"-->
      <!--      @close="addSupplyOutDialog = false"-->
      <!--    />-->

      <DialogWrapper
        :visible="itemDialog.visible"
        @close="itemDialog.visible = false"
        settingDialog="supply"
        :typeDialog="itemDialog.type"
        v-if="itemDialog.visible"
      >
        <OrderDialog  :typeDialog="itemDialog.type" settingDialog="supply" />
      </DialogWrapper>
      <!--      :item="itemDialog.item"-->
      <AddClientDialog :visible="addClientDialog" @close="addClientDialog = false" />
    </VCard>
    <div class="scroll-up__wrapper">
      <v-fab-transition>
        <VBtn
          v-if="isShowUpBtn"
          color="primary"
          small
          absolute
          bottom
          right
          fab
          @click="onScrollToTop"
        >
          <VIcon>mdi-chevron-up</VIcon>
        </VBtn>
      </v-fab-transition>
    </div>
  </div>
</template>

<script>
import supplyService from "../../services/request/supply/supplyService";
import notifications from "../../mixins/notifications";
import loader from "../../mixins/loader";
import AddSupplyDialog from "./AddSupplyDialog.vue";
import OrderDialog from "../orderDialog/OrderDialog.vue";
import EventBus from "../../events/EventBus";
import AddSupplyInDialog from "./AddSupplyInDialog.vue";
// import AddSupplyOutDialog from './AddSupplyOutDialog.vue';
import AddClientDialog from "../users/AddClientDialog.vue";
import SupplyRowList from "./SupplyRowList.vue";
import user from "../../mixins/user";
import depotService from "../../services/request/depot/depotService";
// import PeriodDropdown from './components/PeriodDropdown.vue';
import DialogWrapper from "../orderDialog/DialogWrapper.vue";

export default {
  name: "SupplyComponent",
  mixins: [notifications, loader, user],
  data: () => ({
    itemDialog: {
      visible: false,
      type: "add",
      item: {},
      user_states: []
    },
    isShowUpBtn: false,
    createDialog: false,
    addresses: [],
    products: [],
    depots: [],
    chosenDeports: "",
    searchTTN: "",
    searchLoading: false,
    activeTab: 2,
    customers: [],
    addSupplyInDialog: false,
    addSupplyOutDialog: false,
    addClientDialog: false,
    statesIn: [],
    statesOut: []
  }),
  components: {
    AddSupplyDialog,
    AddSupplyInDialog,
    // AddSupplyOutDialog,
    AddClientDialog,
    SupplyRowList,
    OrderDialog,
    // PeriodDropdown,
    DialogWrapper
  },
  methods: {
    onScrollToTop() {
      this.$scrollTop({}, document.querySelector(".v-data-table__wrapper"));
    },

    onChangeTab(tabNumber) {
      localStorage.setItem("supply-page.activeTab", Number(tabNumber));
    },
    onCreateDialog() {
      if (this.activeTab === 1) {
        this.addSupplyInDialog = true;
      } else if (this.activeTab === 2) {
        this.addSupplyOutDialog = true;
      } else {
        this.createDialog = true;
      }
    },
    async onSearchTTN() {
      this.searchLoading = true;
      await this.getSupplyItems({
        ttn: this.searchTTN
      });
      this.searchLoading = false;
    },
    async getSupplyItems(params = {}) {
      try {
        // eslint-disable-next-line no-param-reassign
        params.offset = 0;
        // eslint-disable-next-line no-param-reassign
        params.limit = 5;
        this.setLoading(false);
      } catch (e) {
        this.searchLoading = false;
        this.setLoading(false);
        this.setErrorNotification(e);
      }
    }
  },
  computed: {
    visibility: {
      get() {
        return this.visible;
      },
      set() {
        this.$emit("close");
        // if (this.itemDialog.type === "add") {
        //   this.dataReset();
        // }
        // this.$destroy();
      }
    }
  },
  async mounted() {
    const activeTabData = localStorage.getItem("supply-page.activeTab");
    this.activeTab = activeTabData ? Number(activeTabData) : 2;
    EventBus.$on("change-page", async params => {
      try {
        this.setLoading(true);
        if (params.type === "out") {
          this.supplyItemsOut = await supplyService.getSupplyList(params);
        } else if (params.type === "in") {
          this.supplyItemsIn = await supplyService.getSupplyList(params);
        } else {
          this.supplyItems = await supplyService.getSupplyList(params);
        }
        this.setLoading(false);
      } catch (e) {
        this.setErrorNotification(e);
        this.setLoading(false);
      }
    });
    EventBus.$on("upBtn-modified", data => {
      this.isShowUpBtn = data;
    });
    try {
      this.setLoading(true);
      this.depots = await depotService.getDepotsList();
      await this.getSupplyItems();
      this.setLoading(false);
    } catch (e) {
      this.setErrorNotification(e);
      this.setLoading(false);
    }
  }
};
</script>

<style scoped lang="scss">
body,
html {
  overflow: hidden;
}
.active-tab-class svg {
  fill: green;
}
.theme--light.v-tabs-items {
  background-color: transparent;
}
.theme--light.v-tabs > .v-tabs-bar {
  background-color: transparent;
}
.supply-tab-counter {
  background: #eeeeee;
  border-radius: 50%;
}
.active-tab-class {
  color: var(--v-green-base) !important;
  i {
    color: var(--v-green-base);
  }
}
.v-slide-group__wrapper .v-tabs-slider-wrapper .v-tabs-slider {
  background: var(--v-green-base) !important;
}
</style>
