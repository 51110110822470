<template>
  <VCard
    class="my-5 mx-2 br-10 pb-3 px-2 pt-2"
    :ripple="{ class: 'blue--text ' }"
    :elevation="3"
    v-on:dblclick="onSupplyItemClick(supplyItem.uuid)"
  >
    <div class="blocked_wrapper" v-if="Number(supplyItem.is_blocked) === 1">
      <VBtn fab elevation="0" x-small color="error">
        <VIcon dense color="white">
          mdi-lock
        </VIcon>
      </VBtn>
    </div>
    <VRow no-gutters justify="end">
      <VBadge
        overlap
        dot
        color="warning"
        v-if="
          supplyItem.cell_details.description !== '' && supplyItem.cell_details.description !== ' '
        "
      >
        <VBtn
          x-small
          circle
          color="primary"
          :elevation="1"
          class="mt-1 mr-1 btn-edit sm circle"
          @click="onSupplyItemClick(supplyItem.uuid)"
          ><VIcon x-small>mdi-file-document-edit-outline</VIcon></VBtn
        >
      </VBadge>
      <VBtn
        v-else-if="supplyItem.uuid_parent !== '00000000-0000-0000-0000-000000000000'"
        x-small
        circle
        color="#D62032"
        :elevation="1"
        class="mt-1 mr-1 btn-edit sm circle"
        @click="onSupplyItemClick(supplyItem.uuid)"
        ><VIcon x-small color="white"> mdi-arrow-u-left-top-bold</VIcon></VBtn
      >
      <VBtn
        v-else
        x-small
        circle
        color="primary"
        :elevation="1"
        class="mt-1 mr-1 btn-edit sm circle"
        @click="onSupplyItemClick(supplyItem.uuid)"
        ><VIcon x-small>mdi-file-document-edit-outline</VIcon></VBtn
      >
    </VRow>
    <VCardText>
      <VRow no-gutters justify="space-between" align-center>
        <VCol cols="12" sm="5">
          <VRow no-gutters justify="space-between">
            <VCol cols="5" class="font-weight-bold text-caption">{{ $t("table.number") }}</VCol>
            <VCol cols="1">–</VCol>
            <VCol
              cols="6"
              class="text-caption text-right elispsis copyTxt-mobile"
              @click="copyToClipboard(supplyItem.number)"
            >
              {{ supplyItem.number || "--" }}</VCol
            >
          </VRow>
          <VRow no-gutters>
            <VCol cols="5" class="font-weight-bold text-caption">ТТН</VCol>
            <VCol cols="1">–</VCol>
            <VCol
              cols="6"
              class="text-caption text-right "
              v-if="supplyItem.ttn == 0 || supplyItem.ttn == null"
            >
              <div class="new__wrapper sm">
                <span>{{ $t("table.new") }}</span>
              </div></VCol
            >
            <VCol
              cols="6"
              class="text-caption text-right elispsis copyTxt-mobile"
              v-else
              @click="copyToClipboard(supplyItem.ttn)"
            >
              {{ supplyItem.ttn || "--" }}</VCol
            >
          </VRow>
          <VRow no-gutters v-if="!permissions.client">
            <VCol cols="5" class="font-weight-bold text-caption elispsis">{{
              $t("table.ns_code")
            }}</VCol>
            <VCol cols="1">–</VCol>
            <VCol
              cols="6"
              class="text-caption text-right elispsis copyTxt-mobile"
              @click="copyToClipboard(supplyItem.cell_details.ns_code)"
              >{{ supplyItem.cell_details.ns_code || "--" }}</VCol
            >
          </VRow>
          <VRow no-gutters>
            <VCol cols="5" class="font-weight-bold text-caption elispsis">{{
              $t("table.name")
            }}</VCol>
            <VCol cols="1">–</VCol>
            <VCol v-if="!permissions.client" cols="6" class="text-caption text-right elispsis ">{{
              supplyItem.user_details.name || "--"
            }}</VCol>
            <VCol v-else cols="6" class="text-caption text-right elispsis ">{{
              supplyItem.delivery_address_details.name || "--"
            }}</VCol>
          </VRow>
          <VRow no-gutters v-if="permissions.client">
            <VCol cols="5" class="font-weight-bold text-caption elispsis">{{
              $t("table.surname")
            }}</VCol>
            <VCol cols="1">–</VCol>
            <VCol cols="6" class="text-caption text-right elispsis ">{{
              supplyItem.delivery_address_details.surname || "--"
            }}</VCol>
          </VRow>
        </VCol>
        <VCol cols="12" sm="2" class="text-center">
          <VDivider
            :vertical="$vuetify.breakpoint.sm"
            :class="{ 'my-3': $vuetify.breakpoint.xs }"
          />
        </VCol>
        <VCol cols="12" sm="5">
          <VRow no-gutters v-if="!permissions.client">
            <VCol cols="5" class="font-weight-bold text-caption elispsis">{{
              $t("table.surname")
            }}</VCol>
            <VCol cols="1">–</VCol>
            <VCol v-if="!permissions.client" cols="6" class="text-caption text-right elispsis ">{{
              supplyItem.user_details.surname || "--"
            }}</VCol>
          </VRow>
          <VRow no-gutters>
            <VCol cols="5" class="font-weight-bold text-caption">{{ $t("table.phone") }}</VCol>
            <VCol cols="1">–</VCol>
            <VCol v-if="!permissions.client" cols="6" class="text-caption text-right copyTxt-mobile"
              ><div
                @click="copyToClipboard(supplyItem.user_details.phone)"
                v-if="supplyItem.user_details.phone && supplyItem.user_details.phone !== '+38'"
                class="elispsis"
              >
                {{ supplyItem.user_details.phone | VMask("+38 (###) ##-##-###") }}
              </div>
              <div v-else>--</div>
            </VCol>
            <VCol v-else cols="6" class="text-caption text-right elispsis copyTxt-mobile"
              ><div
                @click="copyToClipboard(supplyItem.delivery_address_details.phone)"
                v-if="
                  supplyItem.delivery_address_details.phone &&
                    supplyItem.delivery_address_details.phone !== '+38'
                "
                class="elispsis"
              >
                {{ supplyItem.delivery_address_details.phone | VMask("+38 (###) ##-##-###") }}
              </div>
              <div v-else>--</div>
            </VCol>
          </VRow>
          <VRow no-gutters v-if="permissions.client">
            <VCol cols="5" class="font-weight-bold text-caption">{{ $t("table.city") }}</VCol>
            <VCol cols="1">–</VCol>
            <VCol cols="6" class="text-caption text-right elispsis">
              {{ supplyItem.delivery_address_details.name_city || "--" }}</VCol
            >
          </VRow>
          <VRow no-gutters>
            <VCol cols="5" class="font-weight-bold text-caption">{{ $t("table.status") }}</VCol>
            <VCol cols="1">–</VCol>
            <VCol cols="6" class="text-caption text-right delivery ">
              <div class="elispsis status" :class="`status-${supplyItem.id_state} sm`">
                <span class="elispsis"> {{ getStatusText(supplyItem.state_details) }}</span>
              </div>
            </VCol>
          </VRow>
          <VRow no-gutters>
            <VCol cols="5" class="font-weight-bold text-caption elispsis"
              >{{ $t("table.createdData") }}
            </VCol>
            <VCol cols="1">–</VCol>
            <VCol cols="6" class="text-caption text-right elispsis">
              {{
                supplyItem.time_created | createdAt(supplyItem.time_created, currentLanguage)
              }}</VCol
            >
          </VRow>
        </VCol>
      </VRow>
    </VCardText>
    <EditSupplyInDialog
      v-if="editInDialog"
      :visible="editInDialog"
      @close="editInDialog = false"
      :supply="supplyItem"
      @add-customer="$emit('add-customer')"
    />
    <DialogWrapper
      @close="editOutDialog = false"
      v-if="editOutDialog"
      :visible="editOutDialog"
      :typeDialog="'edit'"
    >
      <OrderDialog
        :uuidItem="supplyItem.uuid"
        :typeDialog="'edit'"
        settingDialog="supply"
      ></OrderDialog>
    </DialogWrapper>
    <!--    :item="supplyItem"-->
  </VCard>
</template>

<script>
import supplyStatuses from "../../../constants/states";
import EditSupplyInDialog from "../EditSupplyInDialog.vue";
// import AddSupplyOutDialog from './AddSupplyOutDialog.vue';
import OrderDialog from "../../orderDialog/OrderDialog.vue";
import user from "../../../mixins/user";
import SizeUi from "../../../mixins/SizeUi";
import notifications from "../../../mixins/notifications";
import { mapGetters } from "vuex";
import DialogWrapper from "../../orderDialog/DialogWrapper.vue";

export default {
  mixins: [user, SizeUi, notifications],
  components: {
    EditSupplyInDialog,
    // AddSupplyOutDialog,
    OrderDialog,
    DialogWrapper
  },
  props: {
    supplyItem: {
      required: true
    },
    index: {
      required: true
    }
  },
  data: () => ({
    supplyTypes: {
      in: "Прихід",
      out: "Вихід"
    },

    editInDialog: false,
    editOutDialog: false
  }),
  computed: {
    ...mapGetters(["currentLanguage"])
  },
  methods: {
    getStatusText(item) {
      return item[`${this.currentLanguage.key}_name`];
    },
    async copyToClipboard(e) {
      try {
        await navigator.clipboard.writeText(e);
        this.setSuccessNotification(this.$t("profile.copied_to_clipboard"));
      } catch (e) {
        this.setErrorNotification(e);
      }
    },
    disableBtn(id, client) {
      // eslint-disable-next-line no-mixed-operators
      if ((Number(id) > 400 && client) || Number(id) === 450) {
        return true;
      }
      return false;
    },
    getSupplyStatusText(code) {
      return supplyStatuses[code];
    },
    onSupplyItemClick(item) {
      // console.log(this.supplyItem.type);
      this.$emit("toggleRow", item);
      if (this.supplyItem.type === "in") {
        this.editInDialog = true;
      } else {
        this.editOutDialog = true;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.delivery {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  div {
    max-width: 150px;
    width: auto;
    overflow: hidden;
    background: rgba(160, 192, 241, 0.5);
    border-radius: 10px;
    padding: 0px 10%;
    &.status {
      color: #4d7bc1;
      background: rgba(160, 192, 241, 0.5);
      font-weight: 600;
      font-size: 10px;
    }
  }
}
.blocked_wrapper {
  position: absolute;
  left: -1%;
  top: -3%;
}
</style>
