<template>
  <tr style="position:relative">
    <td class="text-center">
      <div class="blocked_wrapper error darken-1" v-if="Number(supplyItem.is_blocked) === 1">
        <VIcon x-small color="white ">
          mdi-lock
        </VIcon>
      </div>
      <ns-badge
        :show-badge="supplyItem.cell_details.description !== '' && supplyItem.cell_details.description !== ''"
        :ns-border="true"
        ns-color="warning"
      >
        <VBtn
          class="btn-edit "
          :color="
            supplyItem.uuid_parent !== '00000000-0000-0000-0000-000000000000'
              ? '#D62032'
              : 'primary'
          "
          dark
          depressed
          small
          :class="{ sm: !isLarge }"
          @click="onSupplyItemClick(supplyItem.uuid)"
        >
          <VIcon v-if="supplyItem.uuid_parent !== '00000000-0000-0000-0000-000000000000'">
            mdi-arrow-u-left-top-bold
          </VIcon>
          <VIcon v-else>
            mdi-file-document-edit-outline
          </VIcon>
        </VBtn>
      </ns-badge>

    </td>
    <td class="text-center copyTxt" @click="copyToClipboard(supplyItem.number)">
      {{ supplyItem.number || "--" }}
    </td>
    <td class="text-center">
      <div v-if="supplyItem.ttn == 0 || supplyItem.ttn == null">
        <div class="new__wrapper" :class="{ sm: !isLarge }">
          <span>
            {{ $t("table.new") }}
          </span>
        </div>
      </div>
      <div v-else @click="copyToClipboard(supplyItem.ttn)" class="copyTxt">
        {{ supplyItem.ttn || "--" }}
      </div>
    </td>
    <td
      v-if="!permissions.client"
      @click="copyToClipboard(supplyItem.cell_details.ns_code)"
      class="copyTxt"
    >
      {{ supplyItem.cell_details.ns_code || "--" }}
    </td>
    <td class="x-sm">
      <div class="elispsis" v-if="!permissions.client">
        {{ supplyItem.user_details.name || "--" }}
      </div>
      <div class="elispsis" v-else>{{ supplyItem.delivery_address_details.name || "--" }}</div>
    </td>
    <td class="x-sm">
      <div class="elispsis" v-if="!permissions.client">
        {{ supplyItem.user_details.surname || "--" }}
      </div>
      <div class="elispsis" v-else>{{ supplyItem.delivery_address_details.surname || "--" }}</div>
    </td>
    <td class="copyTxt">
      <div v-if="!permissions.client">
        <div
          v-if="supplyItem.user_details.phone"
          @click="copyToClipboard(supplyItem.user_details.phone)"
        >
          {{ supplyItem.user_details.phone | VMask("+38 (###) ##-##-###") }}
        </div>
        <div v-else>--</div>
      </div>
      <div v-else>
        <div
          @click="copyToClipboard(supplyItem.delivery_address_details.phone)"
          v-if="
            supplyItem.delivery_address_details.phone &&
              supplyItem.delivery_address_details.phone !== '+38'
          "
        >
          {{ supplyItem.delivery_address_details.phone | VMask("+38 (###) ##-##-###") }}
        </div>
        <div v-else>--</div>
      </div>
    </td>
    <td v-if="permissions.client" class="md">
      {{ supplyItem.delivery_address_details.name_city || "--" }}
    </td>
    <td class="md">
      <div class="d-flex align-center justify-content-start ">
        <StatusCircle
          :status-name="supplyItem.id_state"
          :type="'supply'"
          :class="{ sm: !isLarge }"
        />
        <div class="elispsis">
          {{ supplyItem.state_details[`${currentLanguage.key}_name`] }}
        </div>
      </div>
      <!-- <span
        v-if="Number(supplyItem.is_blocked) === 1"
        class="text-uppercase px-2 d-inline-block statusBox "
        :class="`status-${supplyItem.id_state}`"
      >
        <VBadge color="error"  dot>
          <b class="elispsis">
            {{ supplyItem.state_details.ua_name || supplyItem.state_details.name || "--" }}
          </b>
        </VBadge>
      </span>
      <span
        v-else
        class="text-uppercase px-2 d-inline-block statusBox "
        :class="`status-${supplyItem.id_state}`"
      >
        <b class="elispsis">
          {{ supplyItem.state_details.ua_name || supplyItem.state_details.name || "--" }}
        </b>
      </span> -->
    </td>

    <td class="text-center">
      {{ supplyItem.time_created | createdAt(supplyItem.time_created, currentLanguage) }}
    </td>

    <EditSupplyInDialog
      v-if="editInDialog"
      :visible="editInDialog"
      @close="editInDialog = false"
      :supply="supplyItem"
      @add-customer="$emit('add-customer')"
    />
    <!--    <AddSupplyOutDialog-->
    <!--      v-if="editOutDialog"-->
    <!--      :visible="editOutDialog"-->
    <!--      :typeDialog="'edit'"-->
    <!--      @close="editOutDialog = false"-->
    <!--      :supply="supplyItem"-->
    <!--    />-->
    <DialogWrapper
      v-if="editOutDialog"
      :visible="editOutDialog"
      @close="editOutDialog = false"
      :typeDialog="'edit'"
      settingDialog="supply"
    >
      <OrderDialog
        :uuidItem="supplyItem.uuid"
        :typeDialog="'edit'"
        settingDialog="supply"
        v-if="editOutDialog"
      ></OrderDialog>
    </DialogWrapper>
    <!--    :item="supplyItem"-->
  </tr>
</template>

<script>
import supplyStatuses from "../../constants/states";
import EditSupplyInDialog from "./EditSupplyInDialog.vue";
// import AddSupplyOutDialog from './AddSupplyOutDialog.vue';
import OrderDialog from "../orderDialog/OrderDialog.vue";
import DialogWrapper from "../orderDialog/DialogWrapper.vue";
import user from "../../mixins/user";
import SizeUi from "../../mixins/SizeUi";
import StatusCircle from "../move-list/StatusCircle.vue";
import notifications from "../../mixins/notifications";
import { mapGetters } from "vuex";

export default {
  name: "SupplyItemRow",
  mixins: [user, SizeUi, notifications],
  components: {
    EditSupplyInDialog,
    // AddSupplyOutDialog,
    OrderDialog,
    StatusCircle,
    DialogWrapper
  },
  props: {
    activeItem:{
      required: true
    },
    supplyItem: {
      required: true
    },
    index: {
      required: true
    }
  },
  data: () => ({
    supplyTypes: {
      in: "Прихід",
      out: "Вихід"
    },

    editInDialog: false,
    editOutDialog: false
  }),
  async mounted() {
    // // orderDialog('supplyItem1', this.supplyItem);
  },
  computed: {
    ...mapGetters(["currentLanguage"])
  },
  methods: {
    async copyToClipboard(e) {
      try {
        await navigator.clipboard.writeText(e);
        this.setSuccessNotification(this.$t("profile.copied_to_clipboard"));
      } catch (e) {
        this.setErrorNotification(e);
      }
    },
    disableBtn(id, client) {
      // eslint-disable-next-line no-mixed-operators
      if ((Number(id) > 400 && client) || Number(id) === 450) {
        return true;
      }
      return false;
    },
    getSupplyStatusText(code) {
      return supplyStatuses[code];
    },
    onSupplyItemClick(item) {
      this.$emit("toggleRow", item);
      if (this.supplyItem.type === "in") {
        this.editInDialog = true;
      } else {
        this.editOutDialog = true;
      }
    }
  }
};
</script>

<style scoped lang="scss">
.statusBox {
  background: rgba(59, 119, 174, 0.1);
  border-radius: 6px;
  font-size: 80%;
  color: rgba(72, 148, 217, 1);
  padding: 2px 5px;
  line-height: 15px;
}
.blocked_wrapper {
  position: absolute;
  left: 0;
  top: 20%;
  padding: 0 3px;
  border-radius: 50%;
}
</style>
