<template>
  <div>
    <v-simple-table
      class="my-table"
      :dense="!isLarge"
      v-if="!$vuetify.breakpoint.smAndDown"
      fixed-header
      :height="getTableHeight"
      :class="{ supply: !items.length && !loading }"
    >
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-center icon"><VIcon dense>mdi-cogs</VIcon></th>
            <th class="text-left sm">
              <div class="header mt-4">
                <span>#</span>
                <VTextField
                  dense
                  hide-details
                  solo
                  v-model="filterProps.number"
                  class="text-caption"
                  clearable
                />
              </div>
            </th>
            <th class="text-left md">
              <div class="header mt-4">
                <span>{{ $t("table.ttn") }}</span>
                <VTextField
                  dense
                  hide-details
                  solo
                  v-model="filterProps.ttn"
                  clearable
                  @input="onScanerField"
                  class="text-caption"
                />
              </div>
            </th>
            <th class="text-left md" v-if="!permissions.client">
              <div class="header mt-4 ">
                <span>{{ $t("table.ns_code") }}</span>
                <VTextField
                  dense
                  @input="onScanerField"
                  hide-details
                  solo
                  v-model="filterProps.cell_details__ns_code"
                  clearable
                  class="text-caption"
                />
              </div>
            </th>
            <th class="text-left sm">
              <div class="header mt-4">
                <span>{{ $t("table.name") }}</span>
                <VTextField
                  dense
                  hide-details
                  solo
                  v-model="filterProps.name"
                  clearable
                  class="text-caption"
                />
              </div>
            </th>
            <th class="text-left sm">
              <div class="header mt-4">
                <span>{{ $t("table.surname") }}</span>
                <VTextField
                  dense
                  hide-details
                  solo
                  v-model="filterProps.surname"
                  clearable
                  class="text-caption"
                />
              </div>
            </th>
            <th class="text-lef md">
              <div class="header mt-4">
                <span>{{ $t("table.phone") }}</span>
                <VTextField
                  dense
                  hide-details
                  solo
                  v-model="filterProps.phone"
                  v-on:keypress="onlyNumber($event)"
                  clearable
                  class="text-caption"
                />
              </div>
            </th>
            <th class="text-left md" v-if="permissions.client">
              <div class="header mt-4">
                <span>{{ $t("table.city") }}</span>
                <VTextField
                  dense
                  hide-details
                  solo
                  v-model="filterProps.delivery_address_details__name_city"
                  clearable
                />
              </div>
            </th>
            <th class="text-left md">
              <div class="header mt-4">
                <span>{{ $t("table.status") }} </span>
                <VSelect
                  dense
                  :placeholder="$t('btn.all')"
                  :items="Object.values(states)"
                  :item-text="getStatusText"
                  :item-value="'id'"
                  hide-details
                  solo
                  v-model="filterProps.state"
                  clearable
                  class="text-caption"
                  :menu-props="{ bottom: true, offsetY: true }"
                >
                  <template v-slot:item="{ item }">
                    <div class="d-flex align-center justify-content-start ">
                      <StatusCircle :status-name="item.id" :type="'supply'" class="pb-1" />
                      <div class="elispsis">
                        {{ getStatusText(item) }}
                      </div>
                    </div>
                  </template>
                </VSelect>
              </div>
            </th>
            <th class="text-left ">
              <div class="header mt-4">
                <span>{{ $t("table.createdData") }}</span>
                <v-menu
                  v-model="dateMenu"
                  :close-on-content-click="false"
                  :nudge-right="-103"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                  class="text-caption"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <VTextField
                      class="bg-white"
                      v-model="filterProps.time_created"
                      readonly
                      append-icon="mdi-calendar"
                      solo
                      dense
                      hide-details
                      v-bind="attrs"
                      v-on="on"
                      clearable
                    />
                  </template>
                  <v-date-picker
                    :locale="currentLanguage.key"
                    no-title
                    :max="NextYear"
                    min="2021"
                    v-model="filterProps.time_created"
                    @input="dateMenu = false"
                  ></v-date-picker>
                </v-menu>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <SupplyItemRow
            v-for="(supplyItem, index) in items"
            :key="supplyItem.uuid"
            :index="index"
            :supply-item="supplyItem"
            :class="{ 'active-row': supplyItem.uuid == activeItem }"
            @toggleRow="toggleRow"
            @add-customer="$emit('add-customer')"
            :activeItem="activeItem"
          />
          <tr>
            <td colspan="9" v-intersect="nextPage" v-if="!loadMoreDisabled && !loading">
              <v-progress-linear
                v-if="isLoadingFilter"
                indeterminate
                color="primary"
              ></v-progress-linear>
            </td>
          </tr>
          <tr v-if="!items.length && !loading">
            <td colspan="9">
              <div class="text-center">
                <h3>{{ $t("table.noData") }}</h3>
              </div>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <div v-else>
      <supply-filter
        :filterPropsExact="filterProps"
        :states="states"
        @onApplyFilter="onApplyFilter"
      />
      <supply-row-item-card
        v-for="(supplyItem, index) in items"
        :key="supplyItem.uuid"
        :index="index"
        :supply-item="supplyItem"
        :activeItem="activeItem"
        :class="{ 'active-card': supplyItem.uuid == activeItem }"
        @toggleRow="toggleRow"
        @add-customer="$emit('add-customer')"
      />
      <div
        v-observer="{ nextPage: nextPage }"
        class="observer"
        v-if="!loadMoreDisabled && !loading"
      ></div>
      <div v-if="!items.length && !loading">
        <div class="text-center">
          <h3>{{ $t("table.noData") }}</h3>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import SupplyItemRow from "./SupplyItemRow.vue";
import EventBus from "../../events/EventBus";
import states from "../../constants/states";
import depotService from "../../services/request/depot/depotService";
import loader from "../../mixins/loader";
import notifications from "../../mixins/notifications";
import supplyService from "../../services/request/supply/supplyService";
import { uniqueByUUID, onlyNumber } from "../../mixins/helpers";
import user from "../../mixins/user";
import SizeUi from "../../mixins/SizeUi";
import SupplyRowItemCard from "./sm/SupplyRowItemCard.vue";
import SupplyFilter from "./sm/SupplyFilter.vue";
import StatusCircle from "@/components/move-list/StatusCircle.vue";
import { mapGetters } from "vuex";

export default {
  name: "SupplyRowList",
  components: {
    SupplyItemRow,
    SupplyRowItemCard,
    SupplyFilter,
    StatusCircle
  },
  mixins: [loader, user, notifications, SizeUi],
  data: () => ({
    isLoadingFilter: false,
    dateMenu: false,
    filterProps: {
      number: "",
      ttn: "",
      cell_details__ns_code: "",
      time_created: "",
      state: "",
      name: "",
      surname: "",
      phone: "",
      // user_details__name: "",
      // user_details__surname: "",
      // user_details__phone: "",
      // delivery_address_details__name: "",
      // delivery_address_details__surname: "",
      // delivery_address_details__phone: "",
      delivery_address_details__name_city: ""
    },
    activeItem: null,
    page: 1,
    perPageList: [5, 10, 15],
    intervalFetchData: null,
    firstNumber: "",
    secondNumber: "",
    total: 12,
    states: [],
    items: [],
    loadMoreDisabled: false,
    totalPages: 0,
    loading: false,
    windowHeight: document.documentElement.clientHeight
  }),
  mounted() {
    window.addEventListener("resize", this.getWindowHeight);

    if (!this.$vuetify.breakpoint.smAndDown) {
      const elem = document.querySelector(".v-data-table__wrapper");
      elem.addEventListener("scroll", this.handleScroll);
    }

    EventBus.$on("supply-in-modified", async () => {
      this.updateList(true);
    });
    EventBus.$on("supply-out-modified", async () => {
      // orderDialog('test')
      this.setLoading(true);
      this.updateList(true);
    });
    this.fretchData();

    this.intervalFetchData = setInterval(() => {
      if (this.getUser !== null) {
        this.updateList(true);
      }
    }, 60000);
  },
  beforeDestroy() {
    clearInterval(this.intervalFetchData);
    window.removeEventListener("resize", this.getWindowHeight);
  },
  watch: {
    loadMoreDisabled(e) {
      console.log(e);
    },
    page(e) {
      if (this.perPage * this.page >= 500 && this.perPage * this.page <= 500 + this.perPage) {
        this.pageBeforeLimit = e;
      }
    },
    async perPage() {
      await this.emitPageChange();
    },
    filterProps: {
      deep: true,
      handler() {
        this.$scrollTop({}, document.querySelector(".v-data-table__wrapper"));
        this.updateList();
      }
    }
  },
  computed: {
    ...mapGetters(["currentLanguage"]),
    largeLimit() {
      const pageLimit = this.perPage * this.page;
      if (pageLimit >= 500) {
        this.page = this.pageBeforeLimit - 1;
        this.$scrollTop();
        return 500;
      }
      return this.perPage * this.page;
    },
    rowHeight() {
      if (this.isLarge) {
        return 48;
      } else {
        return 35;
      }
    },
    perPage() {
      return Math.ceil((window.innerHeight - 150) / this.rowHeight);
    },
    NextYear() {
      const event = new Date().toISOString().substr(0, 12);
      return event;
    },
    nextPageDisabled() {
      return this.items.length < this.perPage;
    },
    getTableHeight() {
      return this.windowHeight - 154 + "px";
    }
  },
  methods: {
    getWindowHeight: _.debounce(function() {
      this.windowHeight = document.documentElement.clientHeight;
    }, 500),

    getStatusText(item) {
      return item[`${this.currentLanguage.key}_name`];
    },
    handleScroll(e) {
      if (document.querySelector(".v-data-table__wrapper").scrollTop > 750) {
        EventBus.$emit("upBtn-modified", true);
      } else {
        EventBus.$emit("upBtn-modified", false);
      }
    },
    onApplyFilter(filterItems) {
      this.filterProps = {
        ...filterItems
      };
      this.updateList();
    },
    onlyNumber,
    onScanerField: _.debounce(function() {
      this.updateList();
    }, 500),
    toggleRow(event) {
      this.activeItem = event;
    },
    async fretchData() {
      try {
        this.setLoading(true);
        this.reorderNumbers();
        this.loadMoreDisabled = true;
        if (this.type === "out") {
          this.states = await depotService.getAllStates({
            type: "DELIVERY_OUT"
          });
        }
        if (this.type === "in") {
          this.states = await depotService.getAllStates({
            type: "DELIVERY_IN"
          });
        }
        if (this.type === "all") {
          const statesIn = await depotService.getAllStates({
            type: "DELIVERY_IN"
          });
          const statesOut = await depotService.getAllStates({
            type: "DELIVERY_OUT"
          });
          this.states = statesIn.concat(statesOut);
        }
        await this.getSupplyItems();
        this.setLoading(false);
        // orderDialog(this.states)
      } catch (e) {
        this.setLoading(false);
        this.setErrorNotification(e);
      }
    },
    updateList(updated) {
      this.getSupplyItems(updated);
    },
    async getSupplyItems(updated) {
      try {
        this.loading = true;
        this.loadMoreDisabled = this.items.length < this.perPage;
        const prefix = this.permissions.client ? "delivery_address_details__" : "user_details__";
        const params = [];
        Object.keys(this.filterProps).forEach(key => {
          if (this.filterProps[key]) {
            params.uselike = true;
            if (key === "state") {
              params.id_state = this.filterProps.state;
            } else if (key === "name" || key === "surname" || key === "phone") {
              params[prefix + key] = this.filterProps[key];
            } else {
              params[key] = this.filterProps[key];
            }
          }
        });

        params.type = this.type;
        // params.uselike = true;
        // orderDialog(params);

        if (this.filter) {
          params.uuid_deport = this.filter;
        }
        if (params.user_details__phone) {
          params.user_details__phone = params.user_details__phone.replace(/[^+\d]/g, "");
        }
        if (params.delivery_address_details__phone) {
          params.delivery_address_details__phone = params.delivery_address_details__phone.replace(
            /[^+\d]/g,
            ""
          );
        }
        console.log(params);
        params.offset = 0;
        params.limit = updated ? this.largeLimit : this.perPage * this.page;
        if (this.type === "out") {
          this.items = await supplyService.getSupplyList({
            type: "out",

            ...params
          });
        }
        if (this.type === "in") {
          this.items = await supplyService.getSupplyList({
            ...params
          });
        }
        // this.totalPages = this.items.length / this.perPage
        // if (this.type === "all") {
        //   this.items = await supplyService.getSupplyList({
        //     offset: 0,
        //     limit: this.perPage * this.page,
        //     ...params
        //   });
        // }
        this.loadMoreDisabled = this.items.length < this.perPage;
        this.setLoading(false);
        this.loading = false;
      } catch (e) {
        this.loading = false;
        this.setLoading(false);
        this.setErrorNotification(e);
      }
    },
    getPaginationParams() {
      return {
        limit: this.perPage,
        offset: (this.page - 1) * this.perPage
      };
    },
    getStatusName(stateId) {
      return states[stateId];
    },
    async prevPage() {
      if (this.page > 1) {
        this.page -= 1;
        await this.emitPageChange();
      }
    },
    async nextPage() {
      console.log("nextPage");
      this.page += 1;
      await this.emitPageChange(true);
    },
    async emitPageChange(filter) {
      try {
        this.reorderNumbers();
        const prefix = this.permissions.client ? "delivery_address_details__" : "user_details__";
        const params = this.getPaginationParams();
        console.log(params)
        Object.keys(this.filterProps).forEach(key => {
          if (this.filterProps[key]) {
            if (key === "state") {
              params.id_state = this.filterProps.state;
            } else if (key === "name" || key === "surname" || key === "phone") {
              params[prefix + key] = this.filterProps[key];
            } else {
              params[key] = this.filterProps[key];
            }
          }
        });
        if (this.filter) {
          params.uuid_deport = this.filter;
        }
        if (Object.keys(params).length > 2) {
          params.uselike = true;
        }
        if (["in", "out"].includes(this.type)) {
          params.type = this.type;
        }
        if (filter) {
          this.isLoadingFilter = true;
        } else {
          this.setLoading(true);
        }
        const newItems = await supplyService.getSupplyList(params);
        // console.log(params)
        // orderDialog(this.loadMoreDisabled);
        this.items.push(...newItems);
        this.items = uniqueByUUID(this.items);
        // this.loadMoreDisabled = this.page > Math.ceil(this.totalPages)
        this.loadMoreDisabled = newItems < this.perPage;

        if (filter) {
          this.isLoadingFilter = false;
        } else {
          this.setLoading(false);
        }
      } catch (e) {
        this.setErrorNotification(e);
        if (filter) {
          this.isLoadingFilter = false;
        } else {
          this.setLoading(false);
        }
      }
      // EventBus.$emit('change-page', params);
    },
    reorderNumbers() {
      this.firstNumber = this.perPage * (this.page - 1) + 1;
      const tempResult = this.page * this.perPage;
      this.secondNumber = tempResult > this.total ? this.total : tempResult;
    }
  },
  props: {
    type: {
      required: true
    },
    filter: String
  }
};
</script>

<style lang="scss">
.supply-items {
  &-header {
    width: 100%;
    border-bottom: 1px solid #f2f2f2;
    .td {
      padding: 4px;
      text-align: center;
      input,
      select {
        border: 1px solid #eeeded;
        padding: 4px;
        &:disabled {
          background-color: #eeeded;
        }
      }
      span {
        font-family: Roboto;
        // margin-bottom: 15px;
        display: block;
        color: #676767;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        // line-height: 21px;
        text-transform: uppercase;
      }
      &.input {
        input,
        select {
          border: unset;
        }
      }
    }
  }
  &-data {
    display: flex;
    align-items: center;
    &:hover {
      background: #c3cedb;
    }
  }
  &-header {
    span {
      overflow: hidden;
      position: relative;
      display: inline-block;
      text-decoration: none;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  &-header,
  &-data {
    margin-bottom: 2px;
    > div {
      padding: 0 5px;
      &:nth-child(1) {
        text-align: center;
        width: 120px;
        min-width: 120px;
      }
      &:nth-child(2) {
        width: 9.5%;
        text-align: center;
        min-width: 100px;
      }
      &:nth-child(3) {
        width: 13%;
        text-align: center;
      }
      &:nth-child(4) {
        width: 10%;
        text-align: center;
      }
      &:nth-child(5) {
        text-align: center;
        width: 13%;
      }
      &:nth-child(6) {
        width: 12%;
        text-align: center;
      }
      &:nth-child(7) {
        width: 16%;
        text-align: center;
      }
      &:nth-child(8) {
        width: 15%;
        text-align: center;
      }
      //&:nth-child(10) {
      //  width: 10%;
      //  text-align: center;
      //}
      &:nth-child(9) {
        width: 60px;
        display: flex;
        justify-content: center;
      }
    }
  }
}
</style>
