<template>
    <SupplyComponent  />
</template>

<script>
import SupplyComponent from '../components/supply/SupplyComponent.vue';

export default {
  name: 'Supply',
  components: {
    SupplyComponent
  },
};
</script>
